<template>
  <!-- banner -->
  <AppBanner :url="require('@/assets/image/cover/shareholder.jpg')" title="投資人專區" />

  <AppContentNav v-model="activeId" :items="navs" />
  <!-- Breadcrumb -->
  <AppBreadcrumb :items="breadcrumb" :border="true" />
  <router-view />
</template>

<script>
export default {
  created() {
    this.updateNav();
  },

  data() {
    return {
      activeId: "SHAREHOLDER",
      currentNav: null,
      navs: [
        { id: 1, name: "重大訊息公告", path: "/shareholder/important-news" },
        { id: 2, name: "法人說明會", path: "/shareholder/investor-conference" },
        { id: 3, name: "股價及股利資訊", path: "/shareholder/price-dividend-info" },
        { id: 4, name: "年報", path: "/shareholder/annual-report" },
        { id: 5, name: "股東會", path: "/shareholder/shareholders-meeting" },
        // { id: 6, name: "主要股東名單", path: "/shareholder/major-shareholders-list" },
        { id: 7, name: "投資人聯絡窗口", path: "/shareholder/investor-contact" },
      ],
    };
  },
  methods: {
    updateNav() {
      const NavName = this.navs.find((n) => n.path === this.$route.path);
      this.currentNavName = NavName ? NavName.name : null;
    },
  },
  computed: {
    breadcrumb() {
      return [
        {
          title: "投資人專區",
          path: "/shareholder/important-news",
        },
        {
          title: this.currentNavName || "-",
        },
      ];
    },
  },
};
</script>
